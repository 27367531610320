var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dimensions-item-indicator"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      placement: 'right',
      content: _vm.getIndicatorTooltip,
      delay: { show: 500, hide: 100 }
      }),expression:"{\n      placement: 'right',\n      content: getIndicatorTooltip,\n      delay: { show: 500, hide: 100 }\n      }"}],staticClass:"material-icons align-text-bottom small-icon ecl-u-type-color-blue-n"},[_vm._v("info_outline")]),_vm._v(" "),_c('div',{staticClass:"indicator"},[_c('p',{staticClass:"text-truncate ecl-u-type-paragraph-m ecl-u-type-color-blue-130 ecl-u-pb-m d-inline-block text-truncate",staticStyle:{"max-width":"165px!important"}},[_vm._v(_vm._s(_vm.indicator))]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
         placement: 'top-center',
         content: _vm.percentageHintTooltip,
         delay: { show: 500, hide: 100 }
         }),expression:"{\n         placement: 'top-center',\n         content: percentageHintTooltip,\n         delay: { show: 500, hide: 100 }\n         }"}]},[(_vm.displayName)?_c('span',{class:{'text-monospace' : _vm.primaryValue}},[_c('p',{staticClass:"ecl-u-type-paragraph-m ecl-u-type-color-blue-130 ecl-u-pb-m"},[_vm._v(_vm._s(_vm._f("formatName")(_vm.primaryValue)))])]):_c('span',[_c('p',{staticClass:"ecl-u-type-paragraph-m ecl-u-type-color-blue-130 ecl-u-pb-m"},[_vm._v(_vm._s(_vm._f("formatValue")(_vm.primaryValue)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }